import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Link } from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Hero extends React.Component {
    render() {
        return (
            <section id='banner' className='homepage'>
                <Container>
                    <Row>
                        <Col className='displayBox'>
                            <h1 className='text-center'>Design. Build. Preserve.</h1>
                            <p className='text-center'>Ochoa Enterprises offers design and executing your vision, building confidence through our workmanship and preserving your home’s character.</p>
                            <button className='btn btn-primary btn-block center'><a href='/about/'>About Our Firm</a></button>
                        </Col>
                    </Row>
                </Container>
                <div className='arrow bounce'>
                    <Link to='scrollDown' activeClass='active' smooth='true'><FontAwesomeIcon className='arrowBtn' icon='chevron-down' /></Link>
                </div>
            </section>
        )
    }
}

export default Hero