import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import { ContactForm, Name, Email } from '../components/Forms'
import ReCAPTCHA from 'react-google-recaptcha'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import IndexSEOImage from '../images/home-banner.jpg'
import workImage from '../images/home-construction.jpg'

class Index extends React.Component {
    constructor(props) {
        super(props)

        this.handleUpdate = this.handleUpdate.bind(this)

        this.state = {
            name: ``,
            email: ``,
            phone_number: ``,
            error: ``,
            loading: false,
        }
    }
    
    handleUpdate = event => {
        if (event.target.name === 'name') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'email') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'subject') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'phone_number') {
            this.setState({
                [event.target.name]: event.target.value.replace(/\D/g, ''),
                error: '',
            })
        }
        this.setState({
            [event.target.name]: event.target.value,
            error: '',
        })
    }

    onChange(value) {
        console.log('Captcha Value:', value);
    }

    render() {
        return (
            <StaticQuery
            query={graphql`
                query HomeMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='index'>
                    <SEO title={data.site.siteMetadata.title} description={data.site.siteMetadata.description} image={IndexSEOImage} keywords={[``]} />
                    <Hero />
                    <section id='services' className='scrollDown content'>
                        <Container>
                            <Row>
                                <Col xs='12' sm='12' md='12' lg='12'>
                                    <h1 className='orangeColor'>Our Services</h1>
                                    <p>Ochoa Enterprises offers planning, coordination, budgeting and overseeing every aspect of your residential or commercial build, construction management, and specialty work.</p>
                                </Col>
                                <div className='iconBlock'>
                                    <Col xs='12' sm='4' md='4' lg='4'>
                                        <a href='/services/'>
                                            <FontAwesomeIcon className='icon blueColor' icon='home' />
                                            <h4 className='orangeColor'>General Contractor</h4>
                                        </a>
                                    </Col>
                                    <Col xs='12' sm='4' md='4' lg='4'>
                                        <a href='/services/'>
                                            <FontAwesomeIcon className='icon blueColor' icon='tools' />
                                            <h4 className='orangeColor'>Construction Management</h4>
                                        </a>
                                    </Col>
                                    <Col xs='12' sm='4' md='4' lg='4'>
                                        <a href='/services/'>
                                            <FontAwesomeIcon className='icon blueColor' icon='toolbox' />
                                            <h4 className='orangeColor'>Specialty Work</h4>
                                        </a>
                                    </Col>
                                    <div className='spacer20'></div>
                                    <button className='btn btn-primary btn-block center'><Link to='/services/'>View All</Link></button>
                                    <div className='spacer20'></div>
                                </div>
                            </Row>
                        </Container>
                    </section>
                    <section id='attorneys' className='content'>
                        <Container fluid>
                            <Row className='center-flex'>
                                <Col className='attorneyImg' xs='12' sm='12' md='5' lg='5'>
                                    <img className='img-responsive center' src={workImage} alt='Home Construction' />
                                </Col>
                                <Col className='padding-left-40' xs='12' sm='12' md='7' lg='7'>
                                    <div className='spacer20'></div>
                                    <h2 className='orangeColor'>Our Work</h2>
                                    <p>Our firms’ founder is an entrepreneur that has many years of experience from his various roles within the Construction industry. These roles have included helper, foreman to project manager. The skills learned from these roles gives us a unique approach to each project we build. Ochoa Enterprises was founded to provide the highest quality customer services tailored to meet each client’s needs.</p>
                                    <p>In the years ahead, the firm continues to grow and expanding our services as a General Contractor and Construction Management firm for our private and public clients. As always, our values continue to drive our growth in the many sectors of the industry.</p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section id='contactForm' className='content'>
                        <ContactForm title='Contact Us' subtitle='We look forward to professionally working with you to solve your construction needs.' error={this.state.error}>
                            <Col xs='12' sm='12' md='6' lg='6'>
                                <Name handleUpdate={this.handleUpdate} name={this.state.name} autoComplete='on' />
                                <Email handleUpdate={this.handleUpdate} email={this.state.email} autoComplete='on' />
                                <fieldset>
                                    <FontAwesomeIcon className='formIcon' icon='pen-nib' />
                                    <input name='subject' placeholder='Subject:' type='text' onChange={this.handleUpdate} value={this.state.subject || ''} required />
                                </fieldset>
                            </Col>
                            <Col xs='12' sm='12' md='6' lg='6'>
                                <fieldset>
                                    <textarea name='message' placeholder='Type your message here...' required></textarea>
                                </fieldset>
                            </Col>
                            <ReCAPTCHA sitekey={data.site.siteMetadata.google.googleReCaptchaKey} onChange={this.onChange} />
                            <div className='spacer20'></div>
                            <button type='submit' className='btn btn-secondary btn-block center' disabled={this.state.loading}>
                            {this.state.loading ? null : 'Send'}
                            {this.state.loading && (
                                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
                            )}
                            </button>
                        </ContactForm>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default Index